<template>
  <div class="notice">
    <!-- 发布通知页面 -->
    <Header
      back="返回"
      title="发布通知"
      index="首页"
      work_bench="工作台"
      beforeTitle="发布通知"
    />
    <!-- 发布通知内容 -->
    <div class="noticeText">
      <div class="textHead">
        <h1 class="noticeTitle" v-for="(item, index) in list" :key="index">
          <span v-if="editings" @dblclick="dbTitle" class="titleSpan">{{
            item.title
          }}</span>
          <span @dblclick="dbTitle" class="titleSpan">{{
            noticeParams.title
          }}</span>
          <el-input
            clearable="true"
            ref="input"
            v-if="editing"
            class="noticeInput"
            v-model.trim="noticeParams.title"
            placeholder="请输入标题"
            @blur="save"
            :value="item.title"
            suffix-icon="el-icon-edit"
          ></el-input>
        </h1>
        <div class="textName">
          <span>发布人：{{ noticeName }}</span>
          <span>添加时间：{{ noticeData }}</span>
        </div>
      </div>
      <el-form ref="formRule" :model="noticeParams">
        <el-form-item style="width: 100%">
          <el-input
            v-model="noticeParams.content"
            type="textarea"
            :autosize="{ minRows: 15, maxRows: 25 }"
            placeholder="请输入发布内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" @click="noticeAdd()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{ title: "双击文本输入发布通知标题" }],
      editing: false,
      editings: true,
      noticeParams: {
        content: "",
        title: "",
      },
      // 发布人名称
      noticeName: {},
      // 发布日期
      noticeData: {},
    };
  },
  created() {
    // console.log(this.$route.query.item, "/item/");
    if (this.$route.query.item) {
      this.noticeParams = this.$route.query.item;
      console.log("编辑跳转");
      //标题
      this.editings = false;
    } else {
      console.log("新增跳转");
    }
    this.axios
      .get("/api/member/view_self", {
        params: {
          token: this.token,
        },
      })
      .then((res) => {
        console.log(res, "个人信息");
        this.noticeName = res.data.true_name;
        this.noticeData = res.data.current_date;
      });
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    dbTitle() {
      console.log("dbTitle");
      this.editing = true;
      this.$nextTick(() => {
        this.$refs.input[0].focus();
      });
    },
    save() {
      this.editing = false;
      if (this.noticeParams.title.length) {
        // 标题
        this.editings = false;
      } else {
        this.$message({
          type: "warning",
          message: "标题不能为空",
        });
        this.editings = true;
      }
    },
    noticeAdd() {
      if (this.noticeParams.id) {
        console.log("编辑执行");
        this.$refs.formRule.validate((vaild) => {
          if (vaild) {
            this.axios
              .post("/api/notice/store", {
                title: this.noticeParams.title,
                content: this.noticeParams.content,
                id: this.noticeParams.id,
              })
              .then((res) => {
                console.log(res);
                this.$router.go(-1);
                this.$message({
                  type: "success",
                  message: "编辑成功!",
                });
              });
          }
        });
      } else {
        if ((this.noticeParams.content, this.noticeParams.title)) {
          this.$refs.formRule.validate((vaild) => {
            if (vaild) {
              this.axios
                .post("/api/notice/store", this.noticeParams)
                .then((res) => {
                  console.log(res);
                  this.$router.push("/notice/noticeMore");
                  this.$message({
                    type: "success",
                    message: "保存成功!",
                  });
                });
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "请输入需要发布的内容或标题",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.notice {
  width: 100%;
  height: 100%;
}
.notice .noticeText {
  width: 95%;
  height: 100%;
  background: #fff;
  padding: 40px;
  margin-top: 15px;
}
.notice .noticeText .textHead h1 {
  height: 40px;
}
.notice .noticeText .textName span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #a6abc7;
  display: inline-block;
  margin: 14px 90px 22px 0;
}
.noticeTitle {
  position: relative;
}
.noticeTitle .titleSpan {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
}
.noticeInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
}
</style>
